import { marked } from 'marked';
import { createClient } from 'contentful';

marked.setOptions({
  headerIds: false,
  mangle: false,
});

const SPACE_ID = 'ujrjk97g2nk9';
const ACCESS_TOKEN = 'f5e4b4ca09cd4c07ecc2469743a3aeda1f9f782ec681413618bf466e548f2485';

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

const processData = (data) => {
  return {
    url: data.fields.file.url,
    title: data.fields.title,
    description: data.fields.description,
    contentType: data.fields.file.contentType
  };
}

const allData = {};

const contentTypes = ["trainingPrograms"]; 

contentTypes.forEach((type) => {
  client.getEntries({ content_type: type })
    .then((response) => {
      allData[type] = response.items;
    })
    .catch(console.error);
});

export const fetchData = (dataType) => {

  switch (dataType) {

    case 'people':
      return client.getEntries({
        content_type: 'peoplePage',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => ({
            personName: entry.fields.employeeName,
            personTitle: entry.fields.workTitle,
            personCategory: entry.fields.workCategory,
            personImage: entry.fields.employeePhoto.fields.file.url,
          }));
        })
        .catch(console.error);

    case "cimShome":
      return client.getEntries({
        content_type: 'cimShome',
        limit: 1000
      })
      .then(entries => {
        return entries.items.map(entry => ({
          aboutusText: entry.fields.aboutusText,
          feature1: entry.fields.feature1,
          feature2: entry.fields.feature2,
          feature3: entry.fields.feature3,
          trainingProgramText: entry.fields.trainingProgramText,
          trainingProgramImage: entry.fields.trainingProgramImage.fields.file.url,
          ourProjectsBlurb: entry.fields.ourProjectsBlurb,
          ourTeamBlurb: entry.fields.ourTeamBlurb,
          publicationsBlurb: entry.fields.publicationsBlurb,
        }));
      })
      .catch(console.error);

    case 'partners':
      return client
        .getEntries({
          content_type: 'partners',
          limit: '1000',
        })
        .then((entries) => {
          const partners = entries.items.map((entry) => {
            let partner = {
              headerImages: entry.fields.headerImages ? entry.fields.headerImages.map(image => {
                return {
                  url: image.fields.file.url,
                  title: image.fields.title,
                  description: image.fields.description,
                  contentType: image.fields.file.contentType
                }
              }) : [],
              partnersInstitutional: entry.fields.partnersInstitutional ? entry.fields.partnersInstitutional.map(image => {
                return {
                  url: image.fields.file.url,
                  title: image.fields.title,
                  description: image.fields.description,
                  contentType: image.fields.file.contentType
                }
              }) : [],
              partnersAcademic: entry.fields.partnersAcademic ? entry.fields.partnersAcademic.map(image => {
                return {
                  url: image.fields.file.url,
                  title: image.fields.title,
                  description: image.fields.description,
                  contentType: image.fields.file.contentType
                }
              }) : [],
              partnersIndustry: entry.fields.partnersIndustry ? entry.fields.partnersIndustry.map(image => {
                return {
                  url: image.fields.file.url,
                  title: image.fields.title,
                  description: image.fields.description,
                  contentType: image.fields.file.contentType
                }
              }) : []
            };
            return partner;
          });
          return partners;
        })
        .catch(console.error);

    case 'projects':
      return client
        .getEntries({
          content_type: 'projects',
          limit: '1000',
        })
        .then((entries) => {
          const projects = entries.items.map((entry) => {
            let project = {
              mainProjectImage: entry.fields.mainProjectImage.fields.file.url,
              projectName: entry.fields.projectName,
              projectText: entry.fields.projectText,
              projectUrl: entry.fields.projectUrl,
              projectDate: entry.fields.projectDate,
              shortDescription: entry.fields.shortDescription,
              category: entry.fields.category || [],
              images: entry.fields.images ? entry.fields.images.map(image => {
                return {
                  url: image.fields.file.url,
                  title: image.fields.title,
                  description: image.fields.description,
                  contentType: image.fields.file.contentType
                }
              }) : [],
              tags: entry.fields.tags ? entry.fields.tags.map(tag => tag.toLowerCase()) : [],
              partners: entry.fields.partners || [],
              headerImages: entry.fields.headerImages ? [entry.fields.headerImages.fields.file.url] : [],
              documents: entry.fields.documents ? entry.fields.documents.map(document => {
                return {
                  url: document.fields.file.url,
                  title: document.fields.title
                }
              }) : [],
              relatedProjects: entry.fields.relatedProjects ? entry.fields.relatedProjects.map(relatedProject => {
                return {
                  projectName: relatedProject.fields.projectName,
                  projectUrl: relatedProject.fields.projectUrl
                };
              }) : []
            };
            return project;
          });
          return projects;
        })
        .catch(console.error);

    case 'detail':
      return client.getEntries({
        content_type: 'detail',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            let detail = {
              detailName: entry.fields.detailName,
              detailUrl: entry.fields.detailUrl,
              category: entry.fields.category,
              background: entry.fields.background,
              date: entry.fields.date,
              builder: entry.fields.builder,
              origin: entry.fields.origin,
              location: entry.fields.location,
              publisher: entry.fields.publisher,
              material: entry.fields.material,
              materialSubcategory: entry.fields.materialSubcategory || [],
              purposeGroup: entry.fields.purposeGroup,
              purpose: entry.fields.purpose,
              drawings: entry.fields.drawings || [],
              modelFiles: entry.fields.modelFiles || [],
              parentAssembly: entry.fields.parentAssembly || [],
              relatedSpecificAssemblies: entry.fields.relatedSpecificAssemblies || [],
              relatedSpecificDetails: entry.fields.relatedSpecificDetails || [],
              relatedTypicalAssemblies: entry.fields.relatedTypicalAssemblies || [],
              relatedTypicalDetails: entry.fields.relatedTypicalDetails || [],
              relatedSources: entry.fields.relatedSources || [],
              photos: entry.fields.photos || []
            };
            return detail;
          });
        })
        .catch(console.error);

    case 'source':
      return client.getEntries({
        content_type: 'source',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            let source = {
              title: entry.fields.title,
              url: entry.fields.url,
              category: entry.fields.category || [],
              description: entry.fields.description,
              publisher: entry.fields.publisher,
              author: entry.fields.author,
              date: entry.fields.date,
              origin: entry.fields.origin,
              location: entry.fields.location,
              material: entry.fields.material || [],
              volumeAndEditionNotes: entry.fields.volumeAndEditionNotes,
              onlineLink: entry.fields.onlineLink,
              downloads: entry.fields.downloads || [],
              coverPage: entry.fields.coverPage,
              related: entry.fields.related || []
            };
            return source;
          });
        })
        .catch(console.error);

    case 'blogs':
      return client.getEntries({
        content_type: 'blogs',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            let blog = {
              blogPostName: entry.fields.blogPostName,
              blogEntryDate: entry.fields.blogEntryDate,
              blogContent: marked(entry.fields.blogContent),
              shortDescription: entry.fields.shortDescription,
              date: entry.fields.date,
              author: entry.fields.author,
              images: entry.fields.images?.map(image => ({
                url: image.fields.file.url,
                title: image.fields.title,
                description: image.fields.description,
                contentType: image.fields.file.contentType,
              })) || [],
              partners: entry.fields.partners || [],
              category: entry.fields.category || [],
              documents: entry.fields.documents?.map(doc => ({
                url: doc.fields.file.url,
                title: doc.fields.title,
              })) || [],
              blogPostUrl: entry.fields.blogPostUrl,
              mainBlogImage: entry.fields.mainBlogImage.fields.file.url,
            };
            return blog;
          });
        })
        .catch(console.error);

    case 'trainingPrograms':
      return client.getEntries({
        content_type: 'trainingPrograms',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => ({
            programTitle: entry.fields.trainingTitle,
            programDescription: entry.fields.description,
            principalInvestigator: entry.fields.principalInvestigator,
            date: entry.fields.date,
            images: entry.fields.images?.map(image => ({
              url: image.fields.file.url,
              title: image.fields.title,
              description: image.fields.description,
            })) || [],
            trainingThumbnail: entry.fields.trainingThumbnail.fields.file.url,
            trainingUrl: entry.fields.trainingUrl,
          }));
        })
        .catch(console.error);

    case 'publications':
      return client.getEntries({
        content_type: 'publications',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            const publication = {
              title: entry.fields.title,
              authorsName: [],
              date: entry.fields.date,
              type: entry.fields.type,
              stream: [],
              publisher: entry.fields.publisher,
              url: entry.fields.url
            };

            if (entry.fields.stream) {
              publication.stream = [...entry.fields.stream];
            };

            if (entry.fields.authorsName) {
              publication.authorsName = [...entry.fields.authorsName];
            };

            return publication;
          });
        })
        .catch(console.error);

    case 'homePageHeader':
      return client.getEntries({
        content_type: 'homePageHeader',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            let homePageHeader = []; 
            if (entry.fields.projects) {
              homePageHeader = [...entry.fields.projects];
            };
            return homePageHeader;
          });
        })
        .catch(console.error);
    
    case 'cdmicaHomePage':
      return client.getEntries({
        content_type: 'cdmicaHomePage',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => ({
            title: entry.fields.title,
            description: entry.fields.description,
            partners: entry.fields.partners || [],
            headerImage: entry.fields.headerImage.fields.file.url,
            instructionText: entry.fields.instructionText,
            instructionTextHeader: entry.fields.instructionTextHeader
          }));
        })
        .catch(console.error);

    case 'aboutUs':
      return client.getEntries({
        content_type: 'aboutUs',
        limit: 1000
      })
        .then(entries => {
          return entries.items.map(entry => {
            let digitizationImages = (entry.fields.digitizationImages || []).map((img, idx) => ({
              id: idx,
              url: img.fields.file.url,
              title: img.fields.title,
              description: img.fields.description,
              contentType: img.fields.file.contentType
            }));

            let modellingImages = (entry.fields.modellingImages || []).map((img, idx) => ({
              id: idx + 10,
              url: img.fields.file.url,
              title: img.fields.title,
              description: img.fields.description,
              contentType: img.fields.file.contentType
            }));

            let dasImages = (entry.fields.dasImages || []).map((img, idx) => ({
              id: idx + 20,
              url: img.fields.file.url,
              title: img.fields.title,
              description: img.fields.description,
              contentType: img.fields.file.contentType
            }));

            let dafImages = (entry.fields.dafImages || []).map((img, idx) => ({
              id: idx + 30,
              url: img.fields.file.url,
              title: img.fields.title,
              description: img.fields.description,
              contentType: img.fields.file.contentType
            }));

            let aboutUsHeaderImages = (entry.fields.aboutUsHeaderImages || []).map((img, idx) => ({
              id: idx + 40,
              url: img.fields.file.url,
              title: img.fields.title,
              description: img.fields.description,
              contentType: img.fields.file.contentType
            }));

            return {
              aboutUsHeaderImages: aboutUsHeaderImages,
              aboutUsTitle: entry.fields.aboutUsTitle,
              aboutUsQuote: entry.fields.aboutUsQuote,
              aboutUsText: entry.fields.aboutUsText,
              whatWeDoText: entry.fields.whatWeDoText,
              researchFocusText: entry.fields.researchFocusText,
              digitizationText: marked(entry.fields.digitizationText || ""),
              digitizationImages: digitizationImages,
              modellingText: entry.fields.modellingText,
              modellingImages: modellingImages,
              dasText: entry.fields.dasText,
              dasImages: dasImages,
              dafText: entry.fields.dafText,
              dafImages: dafImages
            };
          });
        })
        .catch(console.error);

    default:
      console.warn('Unknown data type:', dataType);
      return Promise.resolve();
  }
};
