import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { fetchData } from '../services/contentfulService';
import './Partners.scss';

const Partners = () => {
    const [partnersData, setPartnersData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData('partners')
            .then(data => {
                setPartnersData(data);
            })
            .catch(error => {
                setError(error);
                console.error("Error fetching partners data:", error);
            });
    }, []);

    if (error) {
        return <div>Error loading partners data. Please try again later.</div>
    }

    if (partnersData.length === 0) {
        return <div>Loading...</div>;
    }

    const partners = partnersData[0];
    return (
        <div className='partners'>
            <header className='mb-5'>
            <div className="carousel-wrapper">
                        <Carousel>
                    {partners.headerImages.map((image, index) => (
                        (image.contentType === 'image/jpeg' || image.contentType === 'image/png') && (
                            <Carousel.Item key={index}>
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    height="900"
                                    className="img-fluid"
                                    src={image.url}
                                    alt=""
                                />
                            </Carousel.Item>
                        )
                    ))}
                   </Carousel>
                    </div>
            </header>

            <div className="container partners-content">
                {["Industry", "Institutional", "Academic"].map((type, idx) => {
                    const partnerType = `partners${type}`;
                    return (
                        <div className="row" key={type}>
                            <div className="col-12 col-md-3">
                                    <h2 className="my-3">{type}</h2>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="row">
                                {partners[partnerType].map((partner, index) => (
                                    <React.Fragment key={index}>
                                        <div className="col-6 col-lg-3 my-3">
                                            <a target="_blank" href={partner.description} rel="noopener noreferrer">
                                                <img src={partner.url} alt="" className="img-fluid"/>
                                            </a>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            </div>
                            {idx < 2 && <hr className="my-5"/>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Partners;
