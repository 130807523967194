import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const shouldStick = window.scrollY >= 16;
      setIsSticky(shouldStick);

      if (shouldStick) {
        document.body.classList.add("navbar-fixed");
      } else {
        document.body.classList.remove("navbar-fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.classList.remove("navbar-fixed");
    };
  }, []);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`mobile-top-bar d-lg-none ${isOpen ? "open" : ""}`}>
        <Link className="logo float-start" to="/">
          <img onClick={toggleNav} width="120" src="./assets/cims-logo-black.png" alt="logo" />
        </Link>

        <div className="btn-container">
          <div onClick={toggleNav} className="menu btn12" data-menu="12">
            <div className="icon"></div>
          </div>
        </div>
      </div>

      <div className={`mobile-navigation d-lg-none ${isOpen ? "open" : ""}`}>
        <div>
          <Link onClick={toggleNav} className="aboutus" to="/aboutUs">
            About
          </Link>
          <Link onClick={toggleNav} className="projects" to="/projects">
            Projects
          </Link>
          <Link onClick={toggleNav} className="partners" to="/partners">
            Partners
          </Link>
          <Link onClick={toggleNav} className="ourteam" to="/team">
            Team
          </Link>
          <Link onClick={toggleNav} className="blogs" to="/blogs">
            Blog
          </Link>
          <Link onClick={toggleNav} className="trainingPrograms" to="/trainingPrograms">
            Training
          </Link>
          <Link onClick={toggleNav} className="recruitment" to="/recruitment">
            Recruitment
          </Link>
          <Link onClick={toggleNav} className="publications" to="/publications">
            Publications
          </Link>
          <Link onClick={toggleNav} className="contact" to="/contact">
            Contact
          </Link>
        </div>
      </div>

      <div className={`mobile-navigation-backdrop ${isOpen ? "open" : ""}`} onClick={toggleNav}></div>

      <div className={`desktop-navigation d-none d-lg-block ${isSticky ? "navbar-fixed" : ""}`}>
        <div style={{ padding: 0 }} className="container">
          <Link style={{ margin: 0, padding: 0 }} className="logo" to="/">
            <img width="120" src="./assets/cims-logo-black.png" alt="logo" />
          </Link>
          <div className="float-end">
            <NavLink className="aboutus" to="/aboutUs">
              About
            </NavLink>
            <NavLink className="projects" to="/projects">
              Projects
            </NavLink>
            <NavLink className="partners" to="/partners">
              Partners
            </NavLink>
            <NavLink className="ourteam" to="/team">
              Team
            </NavLink>
            <NavLink className="blogs" to="/blogs">
              Blog
            </NavLink>
            <NavLink className="trainingPrograms" to="/trainingPrograms">
              Training
            </NavLink>
            <NavLink className="publications" to="/publications">
              Publications
            </NavLink>
            <NavLink className="contact" to="/contact">
              Contact
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
