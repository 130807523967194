import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { fetchData } from '../services/contentfulService';
import './Publications.scss';

const Publications = () => {
    const [publications, setPublications] = useState([]);
    const [search, setSearch] = useState('');
    const [publicationFilter, setPublicationFilter] = useState('All');
    const [sourcesOrder, setSourcesOrder] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1124);
    const [filterDisplayText, setFilterDisplayText] = useState('All');

    const headers = [
        { key: 'title', display: 'Title', className: '' },
        { key: 'type', display: 'Type', className: 'd-none d-md-table-cell' },
        { key: 'publisher', display: 'Publisher', className: 'd-none d-sm-table-cell' },
        { key: 'authorsName', display: 'Authors', className: 'd-none d-sm-table-cell' },
        { key: 'stream', display: 'Stream', className: 'd-none d-sm-table-cell' },
        { key: 'date', display: 'Date', className: 'd-none d-sm-table-cell' }
    ];

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1124);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const updateSourceOrder = (key) => {
        if (sourcesOrder === key) {
            setSourcesOrder('-' + key);
        } else {
            setSourcesOrder(key);
        }
    };

    const filteredPublications = publications
        .filter(pub => publicationFilter === 'All' || pub.type?.includes(publicationFilter))
        .filter(pub =>
            pub.title.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));


    const sortedPublications = [...filteredPublications].sort((a, b) => {
        let key = sourcesOrder.startsWith('-') ? sourcesOrder.substring(1) : sourcesOrder;
        let reverse = sourcesOrder.startsWith('-');

        if (key === 'date') {
            return reverse
                ? new Date(a[key]) - new Date(b[key])
                : new Date(b[key]) - new Date(a[key]);
        } else {
            return reverse
                ? ('' + a[key]).localeCompare(b[key])
                : ('' + b[key]).localeCompare(a[key]);
        }
    });

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const handleFilterType = (type) => {
        setPublicationFilter(type);
        setFilterDisplayText(type === 'All' ? 'Filter Publications' : type);
    }

    const tableNavigate = (url) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const getPublicationsData = async () => {
            const publicationData = await fetchData('publications');
            setPublications(publicationData);
        }

        getPublicationsData();
    }, []);

    return (
        <div className="container projects details sources publications">

            <div className="col-12 d-flex flex-column flex-md-row align-items-md-end justify-content-md-between">
                <h1 className="search large-h1 my-3 mb-4">Publications</h1>

                {!isMobile && (
                    <div className='search-container'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={handleSearchChange} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                )}
            </div>


            {isMobile ? (
                <div className="col-12 d-flex align-items-md-end justify-content-between">

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterDisplayText}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="filterDropdown">
                            {['All', 'Conference Proceeding', 'Journal', 'Book', 'White Paper', 'Report'].map(filterType => (
                                <li key={filterType}>
                                    <a className="dropdown-item" onClick={() => handleFilterType(filterType)}>
                                        {filterType}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='search-container'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={handleSearchChange} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                </div>
            ) : (

                <div className={`col-12 publication-filters ${publicationFilter} mt-3`}>
                    {['All', 'Conference Proceeding', 'Journal', 'Book', 'White Paper', 'Report'].map(filterType => (
                        <a
                            key={filterType}
                            className={filterType === publicationFilter ? 'active' : ''}
                            onClick={() => handleFilterType(filterType)}
                        >
                            {filterType}
                        </a>
                    ))}
                </div>
            )}

            <div className="row">
                <div className="col-12 details">
                    <div className="table-responsive my-4">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {headers.map(header => (
                                        <th key={header.key} onClick={() => updateSourceOrder(header.key)} className={`no-select ${header.className}`}>
                                            {header.display}
                                            {sourcesOrder.includes(header.key) && (
                                                <i className="material-icons">
                                                    {sourcesOrder.startsWith('-') && sourcesOrder.includes(header.key) ? 'arrow_drop_down' : 'arrow_drop_up'}
                                                </i>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedPublications.map(publication => (
                                    <tr key={publication.title} onClick={() => tableNavigate(publication.url)}>
                                        <td>
                                            {publication.title}
                                            <i className="material-icons d-sm-none">launch</i>
                                        </td>
                                        <td className="d-none d-md-table-cell">{publication.type}</td>
                                        <td className="d-none d-sm-table-cell">{publication.publisher}</td>
                                        <td className="d-none d-sm-table-cell">{publication.authorsName?.join(', ')}</td>
                                        <td className="d-none d-sm-table-cell">{publication.stream?.join(', ')}</td>
                                        <td className="d-none d-sm-table-cell">{publication.date.split('T')[0]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Publications;
