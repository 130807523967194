import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { fetchData } from '../services/contentfulService';
import { Link } from 'react-router-dom';
import './AboutUs.scss';
import marked from 'marked';

const AboutUs = () => {
    const [aboutUs, setAboutUs] = useState(null);

    useEffect(() => {
        const getAboutUsData = async () => {
            const data = await fetchData('aboutUs');
            setAboutUs(data[0]);
        }

        getAboutUsData();
    }, []);

    if (!aboutUs) {
        return <div>Loading...</div>;
    }

    return (

        <div className='about-us'>
            <header>
                <div>
                    <div className="carousel-wrapper">
                        <Carousel>
                            {aboutUs.aboutUsHeaderImages.map(image => (
                                (image.contentType === 'image/jpeg' || image.contentType === 'image/png') && (
                                    <Carousel.Item key={image.id}>
                                        <img
                                            style={{ backgroundColor: "#f8f8f8" }}
                                            height="900"
                                            className="img-fluid"
                                            src={image.url}
                                            alt={image.title}
                                        />
                                    </Carousel.Item>
                                )
                            ))}
                        </Carousel>
                    </div>
                </div>
            </header>

            <div className="container py-5">
                <h1 className="text-center mb-4">
                    Who we are
                </h1>

                <p className="col-10 mx-auto about-us-header text-center">
                    {aboutUs.aboutUsText}
                </p>
            </div>

            <div className="">
                <div className='aboutus-section'>
                    <div className="container my-5 py-5">
                       
                            <h3 className="about-us-header mb-4">Our Research</h3>
                            <p className="about-us-what-we-do-text">{aboutUs.whatWeDoText}</p>
                     
                    </div>
                </div>
                <div className="container">

                    <div className="row my-5 py-5">
                        <div className="col-12 col-lg-5">
                            {aboutUs.digitizationImages.length > 0 &&
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    height="900"
                                    className="img-fluid mb-4"
                                    src={aboutUs.digitizationImages[0].url}
                                    alt={aboutUs.digitizationImages[0].title}
                                />
                            }
                        </div>

                        <div className="col-lg-1"></div>

                        <div className="col-12 col-lg-6">
                            <h3 className='mb-4'>Digitization</h3>
                            <p dangerouslySetInnerHTML={{ __html: aboutUs.digitizationText }}></p>
                            <Link to={{ pathname: '/projects' }} state={{ projectFilter: 'Digitization' }} className="btn btn-default black">
                                View Digitization Projects
                            </Link>


                        </div>
                    </div>
                </div>
                <div className='aboutus-section'>
                    <div className="container">
                        <div className="row my-5 py-5  ">
                            <div className="col-12 col-lg-6 left order-2 order-lg-1">
                                <h3 className='mb-4'>Modelling</h3>
                                <p>{aboutUs.modellingText}</p>
                                <Link to={{ pathname: '/projects' }} state={{ projectFilter: 'Modelling' }} className="btn btn-default black">
                                    View Modelling Projects
                                </Link>
                            </div>

                            <div className="col-sm-1"></div>

                            <div className="col-12 col-lg-5 order-1 order-lg-2">

                                {aboutUs.modellingImages.length > 0 &&
                                    <img
                                        style={{ backgroundColor: "#f8f8f8" }}
                                        height="900"
                                        className="img-fluid mb-4"
                                        src={aboutUs.modellingImages[0].url}
                                        alt={aboutUs.modellingImages[0].title}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row my-5 py-5">
                        <div className="col-12 col-lg-5 ">
                            {aboutUs.dasImages.length > 0 &&
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    height="900"
                                    className="img-fluid mb-4"
                                    src={aboutUs.dasImages[0].url}
                                    alt={aboutUs.dasImages[0].title}
                                />
                            }
                        </div>

                        <div className="col-sm-1"></div>

                        <div className="col-12 col-lg-6">
                            <h3 className='mb-4'>Digitally Assisted Storytelling</h3>
                            <p>{aboutUs.dasText}</p>
                            <Link to={{ pathname: '/projects' }} state={{ projectFilter: 'Digitally Assisted Storytelling' }} className="btn btn-default black">
                                View Digitally Assisted Storytelling Projects
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='aboutus-section'>
                    <div className="container">
                        <div className="row my-5 py-5">
                            <div className="col-12 col-lg-6 left order-2 order-lg-1">
                                <h3 className='mb-4'>Digitally Assisted Fabrication</h3>
                                <p>{aboutUs.dafText}</p>
                                <Link to={{ pathname: '/projects' }} state={{ projectFilter: 'Digitally Assisted Fabrication' }} className="btn btn-default black">
                                    View Digitally Assisted Fabrication Projects
                                </Link>
                            </div>

                            <div className="col-sm-1"></div>

                            <div className="col-12 col-lg-5 order-1 order-lg-2">
                                {aboutUs.dafImages.length > 0 &&
                                    <img
                                        style={{ backgroundColor: "#f8f8f8" }}
                                        height="900"
                                        className="img-fluid mb-4"
                                        src={aboutUs.dafImages[0].url}
                                        alt={aboutUs.dafImages[0].title}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {aboutUs.saa && aboutUs.saa.length > 0 &&
                    <div className="container">
                        <div className="row my-5 py-5">
                            <div className="col-12 col-lg-5">
                                {aboutUs.saa.length > 0 &&
                                    <img
                                        style={{ backgroundColor: "#f8f8f8" }}
                                        height="900"
                                        className="img-fluid  mb-4"
                                        src={aboutUs.saaImages[0].url}
                                        alt={aboutUs.saaImages[0].title}
                                    />
                                }
                            </div>

                            <div className="col-lg-1"></div>

                            <div className="col-12 col-lg-6">
                                <h3 className='mb-4'>Simulation and Analysis</h3>
                                <p>{aboutUs.saaText}</p>
                                <Link to={{ pathname: '/projects' }} state={{ projectFilter: 'Simulation and Analysis' }} className="btn btn-default black">
                                    View Simulation and Analysis Projects
                                </Link>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

export default AboutUs;
