import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import AboutUs from "./components/AboutUs";
import Blogs from "./components/Blogs";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Partners from "./components/Partners";
import Projects from "./components/Projects";
import Project from "./components/Project";
import Publications from "./components/Publications";
import Team from "./components/Team";
import TrainingPrograms from "./components/TrainingPrograms";
import TrainingProgram from "./components/TrainingProgram";
import HomePage from "./components/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/aboutUs",
        element: <AboutUs />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/blog/:blogPostUrl",
        element: <Blog />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/partners",
        element: <Partners />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/project/:projectUrl",
        element: <Project />,
      },
      {
        path: "/publications",
        element: <Publications />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/trainingprograms",
        element: <TrainingPrograms />,
      },
      {
        path: "/trainingprograms/:trainingUrl",
        element: <TrainingProgram />,
      },
    ],
  },
]);

const root = createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
