import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import Carousel from 'react-bootstrap/Carousel';
import { marked } from 'marked';
import './TrainingProgram.scss';

const TrainigProgram = () => {
    const [trainingProgram, setTrainingProgram] = useState(null);
    const { trainingUrl } = useParams();

    useEffect(() => {
        const getTrainingProgramData = async () => {
            const trainingProgramsData = await fetchData('trainingPrograms');
            const matchedProgram = trainingProgramsData.find(p => p.trainingUrl === trainingUrl);
            if (matchedProgram) {
                setTrainingProgram(matchedProgram);
            } else {
                console.error('No training program found for URL:', trainingUrl);
            }
        }

        getTrainingProgramData();
    }, [trainingUrl]);

    if (!trainingProgram) {
        return null;
    }

    return (
        <div>
            <header>
                <div className="carousel-wrapper">
                    <Carousel indicators={trainingProgram.images.length > 1} controls={trainingProgram.images.length > 1}>
                        {trainingProgram.images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    height="900"
                                    className="img-fluid"
                                    src={image.url}
                                    alt={image.description}
                                />

                                <Carousel.Caption>
                                    <div className="container">
                                        <figure>
                                            <figcaption className="figure-caption">{index + 1}/{trainingProgram.images.length}. {image.description}</figcaption>
                                        </figure>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </header>

            <div className="container mt-4">
                <div className="row">
                <div className="col-12 col-md-4 order-2 order-md-1 project-information">
                        {trainingProgram.principalInvestigator && (
                            <>
                                <h3>Principal Investigator</h3>
                                <ul>
                                    <li>{trainingProgram.principalInvestigator}</li>
                                </ul>
                            </>
                        )}
                        {trainingProgram.date && (
                            <>
                                <h3>Date</h3>
                                <ul>
                                    <li>{trainingProgram.date}</li>
                                </ul>
                            </>
                        )}
                    </div>

                    <div className="col-12 col-md-8 order-1 order-md-2 project-content">
                        <h2>{trainingProgram.programTitle}</h2>
                        <p>{trainingProgram.programDescription}</p>
                        <p dangerouslySetInnerHTML={{ __html: marked(trainingProgram.programDescription) }}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrainigProgram;
