import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import Carousel from 'react-bootstrap/Carousel';
import { marked } from 'marked';
import './Project.scss';

const Project = () => {
    const [project, setProject] = useState(null);
    const { projectUrl } = useParams();

    useEffect(() => {
        const getProjectData = async () => {
            const projectsData = await fetchData('projects');
            const matchedProject = projectsData.find(p => p.projectUrl === projectUrl);
            if (matchedProject) {
                setProject(matchedProject);
            } else {
                console.error('No project found for URL:', projectUrl);
            }
        }

        getProjectData();
    }, [projectUrl]);

    if (!project) {
        return null;
    }

    return (
        <div>
            <header>
                {project.images && (
                    <div className="carousel-wrapper">
                        <Carousel indicators={project.images.length > 1} controls={project.images.length > 1}>
                            {project.images.map((image, index) => (
                                (['image/jpeg', 'image/png'].includes(image.contentType)) &&
                                <Carousel.Item key={index}>
                                    <img
                                        style={{ backgroundColor: "#f8f8f8" }}
                                        height="900"
                                        className="img-fluid"
                                        src={image.url}
                                        alt={image.description}
                                    />
                                    <Carousel.Caption>
                                        <div className="container">
                                            <figure>
                                                <figcaption className="figure-caption">{index + 1}/{project.images.length}. {image.description}</figcaption>
                                            </figure>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                )}
            </header>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 col-md-4 order-2 order-md-1 project-information">
                        {project.category && project.category.length > 0 && (
                            <>
                                <h3>Category</h3>
                                <ul>
                                    {project.category.map(category => <li key={category}>{category}</li>)}
                                </ul>
                            </>
                        )}

                        {project.partners && project.partners.length > 0 && (
                            <>
                                <h3>Partners</h3>
                                <ul>
                                    {project.partners.map(partner => <li key={partner}>{partner}</li>)}
                                </ul>
                            </>
                        )}

                        {project.documents && project.documents.length > 0 && (
                            <>
                                <h3>Documents</h3>
                                <ul>
                                    {project.documents.map(document => (
                                        <li key={document.title}>
                                            <a href={document.url} target="_blank" rel="noopener noreferrer">{document.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {project.relatedProjects && project.relatedProjects.length > 0 && (
                            <>
                             <h3>Related Projects</h3>

                             <ul>
                                    {project.relatedProjects.map(project => (
                                        <li key={project.projectName}>
                                            <a href={project.projectUrl} rel="noopener noreferrer">{project.projectName}</a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                    <div className="col-12 col-md-8 order-1 order-md-2 project-content">
                        <h1 className='mb-4'>{project.projectName}</h1>
                        <p dangerouslySetInnerHTML={{ __html: marked(project.projectText) }}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Project;
