import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Contact.scss';

function Contact() {
    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoibGNob3cxNyIsImEiOiJrSXhaZGFBIn0.U7YSlCGt1GdoD0JXU0EN9Q';

        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/lchow17/cizedxkdl00a82rnqcqzb82oi',
            center: [-75.700602, 45.380949],
            zoom: 12.5
        });

        return () => {
            map.remove();
        };

    }, []);

    return (
        <div className='contact-page'>
            <header className='mx-auto d-flex align-items-center justify-content-center'>
                <div id='map' className="mx-auto" style={{ width: '100%', height: '500px', maxWidth: '1920px' }}></div>
            </header>


            <div className="container">
                <div className="row gx-5" style={{ paddingTop: "48px", paddingBottom: "48px", marginBottom: "48px" }}>

                    <div className="col-12 col-sm-5">
                        <h2 style={{ marginBottom: "24px" }}>Location</h2>
                        <p>
                            <strong>Address:</strong> Carleton Immersive Media Studio
                            Carleton University <br />
                            Visualization and Simulation Building, Fourth Floor<br />
                            1125 Colonel By Drive <br />
                            Ottawa, ON K1S5B6
                        </p>
                    </div>

                    <div className="col-12 col-sm-5 offset-sm-1">
                        <h2 style={{ marginBottom: "24px" }}>Contact Information</h2>
                        <p>
                            <strong>Phone:</strong> 613.520.2600 x3423 <br />
                            <strong>Email:</strong> <a href="mailto:info@cims.carleton.ca">info@cims.carleton.ca</a>
                        </p>
                    </div>

                </div>
            </div>


        </div>
    );
}

export default Contact;
