import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import Carousel from 'react-bootstrap/Carousel';
import { marked } from 'marked';
import './Blog.scss';


const Blog = () => {
    const [blog, setBlog] = useState(null);
    const { blogPostUrl } = useParams();

    useEffect(() => {
        const getSingleBlogData = async () => {
            const blogsData = await fetchData('blogs');
            const matchingBlog = blogsData.find(b => b.blogPostUrl === blogPostUrl);

            setBlog(matchingBlog);
        }

        getSingleBlogData();
    }, [blogPostUrl]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <header>
            <div className="carousel-wrapper">
                <Carousel indicators={blog.images.length > 1} controls={blog.images.length > 1}>
                    {blog.images.map((image, index) => (
                        (['image/jpeg', 'image/png'].includes(image.contentType)) &&
                        <Carousel.Item key={index}>
                            <img
                                style={{ backgroundColor: "#f8f8f8" }}
                                height="900"
                                className="img-fluid"
                                src={image.url}
                                alt={image.description}
                            />
                            <Carousel.Caption>
                                <div className="container">
                                    <figure>
                                        <figcaption class="figure-caption">{index + 1}/{blog.images.length}. {image.description}</figcaption>
                                    </figure>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
                </div>
            </header>

            <div className="container mt-5">
                <div className="row">
                <div className="col-12 col-md-4 order-2 order-md-1 blog-information">
                        {blog.category.length > 0 &&
                            <>
                                <h3>Category</h3>
                                <ul>
                                    {blog.category.map((category, idx) => (
                                        <li key={idx}>{category}</li>
                                    ))}
                                </ul>
                            </>
                        }

                        {blog.partners.length > 0 &&
                            <>
                                <h3>Partners</h3>
                                <ul>
                                    {blog.partners.map((partner, idx) => (
                                        <li key={idx}>{partner}</li>
                                    ))}
                                </ul>
                            </>
                        }

                        {blog.documents.length > 0 &&
                            <>
                                <h3>Documents</h3>
                                <ul>
                                    {blog.documents.map((doc, idx) => (
                                        <li key={idx}><a href={doc.url} target="_blank" rel="noreferrer">{doc.title}</a></li>
                                    ))}
                                </ul>
                            </>
                        }
                    </div>

                    <div className="col-12 col-md-8 order-1 order-md-2 blog-content">
                        <h2 className="blog-title">
                            {blog.blogPostName}
                            <br />
                            <span>{blog.author} on {blog.blogEntryDate}</span>
                        </h2>
                        <div dangerouslySetInnerHTML={{ __html: marked(blog.blogContent) }}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog;
