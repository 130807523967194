import React, { useEffect } from "react";
import { fetchData } from "./services/contentfulService";
import { useLocation, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ReactG4A from "react-ga4";
import "./App.scss";

ReactG4A.initialize("G-Q9J4KRC3QC");

function App() {
  let location = useLocation();

  useEffect(() => {
    ReactG4A.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    fetchData("people").catch(console.error);
    fetchData("partners").catch(console.error);
    fetchData("projects").catch(console.error);
    fetchData("cimShome").catch(console.error);
    fetchData("detail").catch(console.error);
    fetchData("source").catch(console.error);
    fetchData("blogs").catch(console.error);
    fetchData("trainingPrograms").catch(console.error);
    fetchData("publications").catch(console.error);
    fetchData("homePageHeader").catch(console.error);
    fetchData("cdmicaHomePage").catch(console.error);
    fetchData("aboutUs").catch(console.error);
  }, []);

  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
