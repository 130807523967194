import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import './TrainingPrograms.scss';

const TrainingPrograms = () => {
    const [trainingPrograms, setTrainingPrograms] = useState([]);

    useEffect(() => {
        const getTrainingPrograms = async () => {
            const data = await fetchData('trainingPrograms');
            setTrainingPrograms(data);
        }
        getTrainingPrograms();
    }, []);

    return (
        <div className="container projects">
            <div className="col-12">
                <h1 className='search large-h1 my-3 mb-4'>Training Programs</h1>
            </div>
            <div className="row">
                {trainingPrograms.map((program, index) => (
                    <React.Fragment key={program.trainingUrl}>
                        {index % 3 === 0 && (<div className="clearfix partner-spacing"></div>)}
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <Link to={`/trainingPrograms/${program.trainingUrl}`} className="text-decoration-none">

                                <div
                                    className="training-program-container"
                                    style={{ backgroundImage: `url(${program.trainingThumbnail})` }}>
                                    <h4>{program.programTitle}</h4>

                                </div>
                            </Link>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default TrainingPrograms;
