import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import './Home.scss';
import Carousel from 'react-bootstrap/Carousel';

const HomePage = () => {
    const [homePageHeader, setHomePageHeader] = useState([]);
    const [cimShome, setCimShome] = useState(null);
    const [firstBlog, setFirstBlog] = useState(null);

    useEffect(() => {
        const getHomePageData = async () => {
            const headerData = await fetchData('homePageHeader');
            setHomePageHeader(headerData.flat());

            const cimShomeData = await fetchData('cimShome');
            if (cimShomeData && cimShomeData.length > 0) {
                setCimShome(cimShomeData[0]);
            } else {
                console.error('No data found for cimShome');
            }
        }


        getHomePageData();
    }, []);

    useEffect(() => {
        async function fetchFirstBlog() {
            const blogs = await fetchData('blogs');
            if (blogs && blogs.length > 0) {
                setFirstBlog(blogs[0]);
            }
        }

        fetchFirstBlog();
    }, []);

    if (!cimShome) {
        return null;
    }

    if (!firstBlog) {
        return null;
    }

    return (
        <div className='home-page'>
            <header className='home-header'>
                {homePageHeader && (
                    <div className="carousel-wrapper">
                        <Carousel>
                            {homePageHeader.map((project, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        style={{ backgroundColor: "#f8f8f8" }}
                                        height="900"
                                        className="img-fluid"
                                        src={project.fields.headerImages.fields.file.url}
                                        alt={project.fields.projectName}
                                    />
                                    <div className="container position-absolute top-50 start-50 translate-middle">
                                        <div className="col-12 text-center d-flex flex-column justify-content-center align-items-center" style={{ height: "900px" }}>
                                            <h1>{project.fields.projectName}</h1>
                                            <Link to={`/project/${project.fields.projectUrl}`} className="btn btn-default black mt-3">View Project</Link>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                )}
            </header>


            <div className="row justify-content-center m-0 py-lg-5 my-5">
                <div className="col-10 col-md-8 home-page-about-us-block">
                    <p>
                        {cimShome.aboutusText}
                    </p>
                    <Link to={`/aboutus`} className="btn btn-default black">Learn More</Link>
                </div>
            </div>


            <div className="home-page-projects-block py-5" >
                <div className="container">

                    <h2 className='mb-4 '>Featured Projects</h2>

                    <div className="row">

                        {cimShome.feature1?.fields?.projectUrl && (
                            <div className="col-12 col-md-6 mb-2 mb-2 mb-md-0">
                                <Link to={`/project/${cimShome.feature1.fields.projectUrl}`} className="text-decoration-none">
                                    <div
                                        className="project-container"
                                        style={{ backgroundImage: `url(${cimShome.feature1.fields.images[0].fields.file.url})` }}>
                                        <h4>{cimShome.feature1.fields.projectName}</h4>
                                    </div>
                                </Link>
                            </div>
                        )}

                        {cimShome.feature2?.fields?.projectUrl && (
                            <div className="col-12 col-md-6">

                                <Link to={`/project/${cimShome.feature2.fields.projectUrl}`} className="text-decoration-none">
                                    <div
                                        className="project-container"
                                        style={{ backgroundImage: `url(${cimShome.feature2.fields.images[0].fields.file.url})` }}>
                                        <h4>{cimShome.feature2.fields.projectName}</h4>
                                    </div>
                                </Link>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <div className="home-page-blog mb-5 py-lg-5">
                <div className="container">

                    <div className="row">
                        <div className="col-12 col-sm-6 home-page-blog-block-text-container">
                            <h2><strong>Check out <br/> our most recent <br/>Blog post</strong></h2>
                            <Link className="btn btn-default black d-none d-sm-block" to={`/blog/${firstBlog.blogPostUrl}`}>View Blog</Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Link to={`/blog/${firstBlog.blogPostUrl}`}>
                                <img style={{ backgroundColor: "#f8f8f8" }} className="img-fluid" src={firstBlog.images[0].url} alt="Recent blog post" />
                            </Link>
                        </div>
                    </div>

                    <div className="row d-block d-sm-none">
                        <div className="col-12 col-sm-6 home-page-blog-block-text-container">
                            <Link className="btn btn-default black" to={`/blog/${firstBlog.blogPostUrl}`}>View Blog</Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className="home-page-training-programs mb-5 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <img
                                style={{ backgroundColor: "#f8f8f8" }}
                                className="img-fluid"
                                src={cimShome.trainingProgramImage}
                                alt="Training Program"
                            />
                        </div>
                        <div className="col-12 col-lg-6 home-page-training-programs-text-container">
                            <h2 className='my-4'>Training Programs</h2>
                            <p>{cimShome.trainingProgramText}</p>
                            <Link className="btn btn-default black" to="/trainingPrograms">View Training Programs</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-4">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <Link to="/projects?projectFilter=All" style={{ textDecoration: 'none' }}>
                            <div className='learnmore-section mt-3 mt-md-0'>
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    className="img-fluid mb-3"
                                    src={`${process.env.PUBLIC_URL}/assets/projects1.jpg`}
                                />
                                <h5>Discover our projects</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4">
                        <Link to="/team" style={{ textDecoration: 'none' }}>
                            <div className='learnmore-section mt-3 mt-md-0'>
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    className="img-fluid mb-3"
                                    src={`${process.env.PUBLIC_URL}/assets/aboutus1.jpg`}
                                />
                                <h5>Meet the people behind CIMS</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4">
                        <Link to="/publications" style={{ textDecoration: 'none' }}>
                            <div className='learnmore-section mt-3 mt-md-0'>
                                <img
                                    style={{ backgroundColor: "#f8f8f8" }}
                                    className="img-fluid mb-3"
                                    src={`${process.env.PUBLIC_URL}/assets/publications1.jpg`}
                                />
                                <h5>Read our publications</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="home-page-miah mt-5 py-5">
                <div className="container">
                    <div className="row">

                    <div className="col-12 col-lg-5 home-page-training-programs-text-container">
                            <h2 className='my-4'>MIAH</h2>
                            <p>The Multimedia Inventory of Architectural Heritage (MIAH) brings together academics, heritage professionals, archivists, and industry leaders in software and computing to work as partners in the development of an innovative, online knowledge base that provides the tools necessary for integrative research on both tangible and intangible qualities related to the fabrication of our architectural heritage.</p>
                            <Link className="btn btn-default black" to="http://www.cims-miah.com/">Visit MIAH</Link>
                        </div>

                        <div className="col-12 col-lg-6 offset-lg-1aa">
                            <img
                                style={{ backgroundColor: "#f8f8f8" }}
                                className="img-fluid"
                                src={`${process.env.PUBLIC_URL}/assets/home-miah.jpg`}
                                alt="Training Program"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
