import React, { useEffect, useState } from 'react';
import { fetchData } from '../services/contentfulService';
import './Team.scss';

const Team = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [personFilter, setPersonFilter] = useState('Current');
    const categories = ['Current', 'Management', 'Team Leads', 'Research', 'Technical ', 'Faculty', 'Industry', 'Alumni'];
    const [filterDisplayText, setFilterDisplayText] = useState('Current');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1124);

    useEffect(() => {
        const getTeamData = async () => {
            const teamData = await fetchData('people');
            setTeamMembers(teamData);
        }

        getTeamData();
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1124);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePersonFilterChange = (category) => {
        setPersonFilter(category);
        setFilterDisplayText(category);
    }

    const filterPersons = (person) => {
        if (personFilter === 'Current') {
            return person.personCategory !== 'Alumni';
        }
        else if (personFilter === 'Team Leads') {
            return person.personCategory.includes('Team Lead');
        }
        else if (personFilter === 'Faculty') {
            return person.personCategory.includes('Faculty');
        }
        else if (personFilter === 'Techincal ') {
            return person.personCategory.includes('Technical');
        }
        else {
            return person.personCategory === personFilter;
        }
    }

    const sortedTeamMembers = [...teamMembers].sort((a, b) => {
        if (a.personName < b.personName) return -1;
        if (a.personName > b.personName) return 1;
        return 0;
    });

    return (
        <div className="our-team">
            <div className="container projects">
                <div className="col-12  d-flex justify-content-between align-items-center">
                    <h1 className='search large-h1 my-3 mb-4'>Our Team</h1>
                </div>
                {isMobile ? (
                    <div className="dropdown mt-3">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterDisplayText}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="filterDropdown">
                            {categories.map((category, index) => (
                                <li key={index}>
                                    <a className="dropdown-item" onClick={() => handlePersonFilterChange(category)}>
                                        {category}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (

                    <div className={`col-12 team-filters ${personFilter}`}>
                        {categories.map((category, index) => (
                            <a key={index} onClick={() => setPersonFilter(category)}>{category}</a>
                        ))}
                    </div>
                )}
            </div>

            <div className="container mt-3">
                <div className="row">
                    {sortedTeamMembers.filter(filterPersons).map((member, index) => (
                        <div key={index} className={`person ${member.personCategory} col-6 col-md-3 col-lg-2 ourteam-container`}>
                            <img className="img-responsive" src={member.personImage} alt={member.personName} height="400" />
                            <div className="ourteam-content">
                                <h4>{member.personName}</h4>
                                <p className="person-title">{member.personTitle}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Team;
