import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import './Projects.scss';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [search, setSearch] = useState('');
    const [projectFilter, setProjectFilter] = useState('All');
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1124);
    const [filterDisplayText, setFilterDisplayText] = useState('All');

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1124);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFilterType = (type) => {
        setProjectFilter(type);
        setFilterDisplayText(type);
    }

    const filteredProjects = projects
        .filter(project => projectFilter === 'All' || project.category.includes(projectFilter))
        .filter(project =>
            project.projectName.toLowerCase().includes(search.toLowerCase()) ||
            (project.projectText && project.projectText.toLowerCase().includes(search.toLowerCase()))
        )
        .sort((a, b) => new Date(b.projectDate) - new Date(a.projectDate));

    useEffect(() => {
        const getProjectsData = async () => {
            const projectData = await fetchData('projects');
            setProjects(projectData);
        }
        if (location.state && location.state.projectFilter) {
            setProjectFilter(location.state.projectFilter);
        }
        getProjectsData();
    }, []);

    return (
        <div className="container projects">
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-end justify-content-md-between">
                <h1 className="search large-h1 my-3 mb-4">Projects</h1>

                {!isMobile && (
                    <div className='search-container'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={e => setSearch(e.target.value)} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                )}
            </div>

            {isMobile ? (

                <div className="col-12 d-flex align-items-md-end justify-content-between">

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterDisplayText}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className={`dropdown-item ${projectFilter === 'All' ? 'active' : ''}`} onClick={() => handleFilterType('All')}>All</a></li>
                            <li><a className={`dropdown-item ${projectFilter === 'Digitization' ? 'active' : ''}`} onClick={() => handleFilterType('Digitization')}>Digitization</a></li>
                            <li><a className={`dropdown-item ${projectFilter === 'Modelling' ? 'active' : ''}`} onClick={() => handleFilterType('Modelling')}>Modelling</a></li>
                            {/* <li><a className={`dropdown-item ${projectFilter === 'Simulation and Analysis' ? 'active' : ''}`} onClick={() => handleFilterType('Simulation and Analysis')}>Simulation and Analysis</a></li> */}
                            <li><a className={`dropdown-item ${projectFilter === 'Digitally Assisted Fabrication' ? 'active' : ''}`} onClick={() => handleFilterType('Digitally Assisted Fabrication')}>Digitally Assisted Fabrication</a></li>
                            <li><a className={`dropdown-item ${projectFilter === 'Digitally Assisted Storytelling' ? 'active' : ''}`} onClick={() => handleFilterType('Digitally Assisted Storytelling')}>Digitally Assisted Storytelling</a></li>
                        </ul>
                    </div>

                    <div className='search-container side-by-side'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={e => setSearch(e.target.value)} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                </div>
            ) : (
                <div className={`col-12 project-filters ${projectFilter} mt-3`}>
                    <a onClick={() => handleFilterType('All')}>All</a>
                    <a onClick={() => handleFilterType('Digitization')}>Digitization</a>
                    <a onClick={() => handleFilterType('Modelling')}>Modelling</a>
                    {/* <a onClick={() => handleFilterType('Simulation and Analysis')}>Simulation and Analysis</a> */}
                    <a onClick={() => handleFilterType('Digitally Assisted Fabrication')}>Digitally Assisted Fabrication</a>
                    <a onClick={() => handleFilterType('Digitally Assisted Storytelling')}>Digitally Assisted Storytelling</a>
                </div>
            )}

            <div className="row mt-3">
                {filteredProjects.map((project, index) => (
                    <div key={project.projectUrl} className="col-12 col-sm-12 col-md-6 col-lg-4">
                        <Link to={`/project/${project.projectUrl}`} className="text-decoration-none">
                            <div className="project-container" style={{ backgroundImage: `url(${project.mainProjectImage})` }}>
                                <h4>{project.projectName}</h4>
                            </div>
                        </Link>
                    </div>
                ))}

            </div>
        </div>
    );
}

export default Projects;
