import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../services/contentfulService';
import './Blogs.scss';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [search, setSearch] = useState('');
    const [blogFilter, setBlogFilter] = useState('All');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1124);
    const [filterDisplayText, setFilterDisplayText] = useState('All');

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1124);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleFilterType = (type) => {
        setBlogFilter(type);
        setFilterDisplayText(type);
    }

    const filteredBlogs = blogs
        .filter(blog => blogFilter === 'All' || blog.category.includes(blogFilter))
        .filter(blog =>
            blog.blogPostName.toLowerCase().includes(search.toLowerCase()) ||
            blog.author.toLowerCase().includes(search.toLowerCase()) ||
            blog.blogEntryDate.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => new Date(b.blogEntryDate) - new Date(a.blogEntryDate));

    useEffect(() => {
        const getBlogsData = async () => {
            const blogData = await fetchData('blogs');
            setBlogs(blogData);
        }

        getBlogsData();
    }, []);

    return (
        <div className="container projects">
            <div className="col-12 d-flex flex-column flex-md-row align-items-md-end justify-content-md-between">
                <h1 className="search large-h1 my-3 mb-4">Blogs</h1>

                {!isMobile && (
                    <div className='search-container'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={e => setSearch(e.target.value)} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                )}
            </div>
            {isMobile ? (
                <div className="col-12 d-flex align-items-md-end justify-content-between">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="blogFilterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterDisplayText}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="blogFilterDropdown">
                            {['All', 'Digitization', 'Modelling', 'Digitally Assisted Fabrication', 'Digitally Assisted Storytelling'].map(filterType => (
                                <li key={filterType}>
                                    <a className="dropdown-item" onClick={() => handleFilterType(filterType)}>
                                        {filterType}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='search-container side-by-side'>
                        <label htmlFor="search_bar">Search</label>
                        <input value={search} onChange={e => setSearch(e.target.value)} className="search_bar"
                            name="search_bar"
                            type="search"
                            placeholder="Search" />
                    </div>
                </div>

            ) : (

                <div className={`col-12 blog-filters ${blogFilter}`}>
                    <div className="main-filters d-flex">
                        <a onClick={() => handleFilterType('All')}>All</a>
                        <a onClick={() => handleFilterType('Digitization')}>Digitization</a>
                        <a onClick={() => handleFilterType('Modelling')}>Modelling</a>
                        <a onClick={() => handleFilterType('Digitally Assisted Fabrication')}>Digitally Assisted Fabrication</a>
                        <a onClick={() => handleFilterType('Digitally Assisted Storytelling')}>Digitally Assisted Storytelling</a>
                    </div>
                </div>
            )}

            <div className="row mt-3">
                {filteredBlogs.map((blog, index) => (
                    <div  key={blog.blogPostUrl} className="col-12 col-sm-12 col-md-6 col-lg-4">
                        <Link to={`/blog/${blog.blogPostUrl}`} className="text-decoration-none">
                            <div
                                className="blog-container"
                                style={{ backgroundImage: `url(${blog.mainBlogImage})` }}>
                                <h4>{blog.blogPostName}</h4>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Blogs;
